import CryptoJS from 'crypto-js';
import UAParser from 'ua-parser-js';

export const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    browser: `${result.browser.name} ${result.browser.version}`,
    os: `${result.os.name} ${result.os.version}`,
    device: result.device.model ? `${result.device.vendor} ${result.device.model}` : 'Desktop'
  };
};

export function getConfigDetails() {
  let userToken = '';
  let companyToken = '';
  let appToken = '';
  let leadToken = '';
  let userIdToken = '';

  // get userToken code
  const pageCode = sessionStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d74');
  console.log('pageCode', pageCode);
  console.log('pageCode', typeof pageCode);
  if (
    pageCode !== null &&
    pageCode !== undefined &&
    pageCode !== '' &&
    pageCode !== 'null' &&
    pageCode !== 'undefined'
  ) {
    // if (pageCode) {
    //   try {
    //     // Decode base64 first
    //     const decodedBase64 = atob(pageCode);

    //     // Decrypt using AES
    //     const pageCodeBytes = CryptoJS.AES.decrypt(decodedBase64, 'userToken');
    //     userToken = pageCodeBytes.toString(CryptoJS.enc.Utf8);
    //   } catch (error) {
    //     console.error('Error decoding:', error);
    //     // Handle the error, e.g., show an error message or fallback value
    //   }
    // }

    const pageCodeBytes = CryptoJS.AES.decrypt(pageCode, 'userToken');
    if (
      pageCodeBytes !== null &&
      pageCodeBytes !== undefined &&
      pageCodeBytes !== '' &&
      pageCodeBytes !== 'null' &&
      pageCodeBytes !== 'undefined'
    ) {
      try {
        const str = pageCodeBytes.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          userToken = pageCodeBytes.toString(CryptoJS.enc.Utf8);
        } else {
          userToken = '';
        }
        console.log('isError res', userToken);
      } catch (e) {
        console.log('isError', e);
        try {
          const str = pageCodeBytes.toString(CryptoJS.enc.Hex);
          if (str.length > 0) {
            userToken = pageCodeBytes.toString(CryptoJS.enc.Hex);
          } else {
            userToken = '';
          }
          console.log('isError res', userToken);
        } catch (e) {
          console.log('isError 2', e);
          userToken = '';
        }
      }
    }
  }

  // get CompanyIdCode
  const pageCode2 = sessionStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d75');
  if (
    pageCode2 !== null &&
    pageCode2 !== undefined &&
    pageCode2 !== '' &&
    pageCode2 !== 'null' &&
    pageCode2 !== 'undefined'
  ) {
    const pageCodeBytes2 = CryptoJS.AES.decrypt(pageCode2, 'companyToken');
    if (
      pageCodeBytes2 !== null &&
      pageCodeBytes2 !== undefined &&
      pageCodeBytes2 !== '' &&
      pageCodeBytes2 !== 'null' &&
      pageCodeBytes2 !== 'undefined'
    ) {
      try {
        const str = pageCodeBytes2.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          companyToken = pageCodeBytes2.toString(CryptoJS.enc.Utf8);
        } else {
          companyToken = '';
        }
        console.log('isError res', companyToken);
      } catch (e) {
        console.log('isError', e);
        try {
          const str = pageCodeBytes2.toString(CryptoJS.enc.Hex);
          if (str.length > 0) {
            companyToken = pageCodeBytes2.toString(CryptoJS.enc.Hex);
          } else {
            companyToken = '';
          }
          console.log('isError res', companyToken);
        } catch (e) {
          console.log('isError 2', e);
          companyToken = '';
        }
      }
    }
  }

  // get Application ID code
  const pageCode3 = sessionStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d76');
  if (
    pageCode3 !== null &&
    pageCode3 !== undefined &&
    pageCode3 !== '' &&
    pageCode3 !== 'null' &&
    pageCode3 !== 'undefined'
  ) {
    const pageCodeBytes3 = CryptoJS.AES.decrypt(pageCode3, 'appToken');
    if (
      pageCodeBytes3 !== null &&
      pageCodeBytes3 !== undefined &&
      pageCodeBytes3 !== '' &&
      pageCodeBytes3 !== 'null' &&
      pageCodeBytes3 !== 'undefined'
    ) {
      try {
        const str = pageCodeBytes3.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          appToken = pageCodeBytes3.toString(CryptoJS.enc.Utf8);
        } else {
          appToken = '';
        }
        console.log('isError res', appToken);
      } catch (e) {
        console.log('isError', e);
        try {
          const str = pageCodeBytes3.toString(CryptoJS.enc.Hex);
          if (str.length > 0) {
            appToken = pageCodeBytes3.toString(CryptoJS.enc.Hex);
          } else {
            appToken = '';
          }
          console.log('isError res', appToken);
        } catch (e) {
          console.log('isError 2', e);
          appToken = '';
        }
      }
      // appToken = pageCodeBytes3.toString(CryptoJS.enc.Utf8);
    }
  }

  // get lead id code
  const pageCode4 = sessionStorage.getItem('e13ee06b-72e6-4581-86a3-c0a465e63d77');
  if (
    pageCode4 !== null &&
    pageCode4 !== undefined &&
    pageCode4 !== '' &&
    pageCode4 !== 'null' &&
    pageCode4 !== 'undefined'
  ) {
    const pageCodeBytes4 = CryptoJS.AES.decrypt(pageCode4, 'leadToken');
    if (
      pageCodeBytes4 !== null &&
      pageCodeBytes4 !== undefined &&
      pageCodeBytes4 !== '' &&
      pageCodeBytes4 !== 'null' &&
      pageCodeBytes4 !== 'undefined'
    ) {
      try {
        const str = pageCodeBytes4.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          leadToken = pageCodeBytes4.toString(CryptoJS.enc.Utf8);
        } else {
          leadToken = '';
        }
        console.log('isError res', leadToken);
      } catch (e) {
        console.log('isError', e);
        try {
          const str = pageCodeBytes4.toString(CryptoJS.enc.Hex);
          if (str.length > 0) {
            leadToken = pageCodeBytes4.toString(CryptoJS.enc.Hex);
          } else {
            leadToken = '';
          }
          console.log('isError res', leadToken);
        } catch (e) {
          console.log('isError 2', e);
          leadToken = '';
        }
      }
      // leadToken = pageCodeBytes4.toString(CryptoJS.enc.Utf8);
    }
  }

  // get UserId code
  const pageCode5 = sessionStorage.getItem('e13ee06bu-72e6s-4581e-86a3r-c0a465e63d77');
  if (
    pageCode5 !== null &&
    pageCode5 !== undefined &&
    pageCode5 !== '' &&
    pageCode5 !== 'null' &&
    pageCode5 !== 'undefined'
  ) {
    const pageCodeBytes5 = CryptoJS.AES.decrypt(pageCode5, 'userId');
    if (
      pageCodeBytes5 !== null &&
      pageCodeBytes5 !== undefined &&
      pageCodeBytes5 !== '' &&
      pageCodeBytes5 !== 'null' &&
      pageCodeBytes5 !== 'undefined'
    ) {
      try {
        const str = pageCodeBytes5.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          userIdToken = pageCodeBytes5.toString(CryptoJS.enc.Utf8);
        } else {
          userIdToken = '';
        }
        console.log('isError res', userIdToken);
      } catch (e) {
        console.log('isError', e);
        try {
          const str = pageCodeBytes5.toString(CryptoJS.enc.Hex);
          if (str.length > 0) {
            userIdToken = pageCodeBytes5.toString(CryptoJS.enc.Hex);
          } else {
            userIdToken = '';
          }
          console.log('isError res', userIdToken);
        } catch (e) {
          console.log('isError 2', e);
          userIdToken = '';
        }
      }
      // userIdToken = pageCodeBytes5.toString(CryptoJS.enc.Utf8);
    }
  }
  let urlCompanyId = '';
  let urlJWTtoken = '';
  let urlAppToken = '';
  let urlUserId = '';
  let urlLoanId = '';
  let urlloanTypeId = '';
  let urlLoanNewId = '';
  let urlLoanSubId = '';
  let urlEmpId = '';
  let urlEmpSubId = '';
  let urlEmpTypeToken = '';
  let urlEmpSubTypeToken = '';
  let urlLoanCodeToken = '';
  let urlLead = '';
  let urlAwn = '';
  let locationValue = '';
  // admin
  let u = '';
  let c = '';
  let a = '';
  let s = '';
  let m = '';
  let sc = '';
  let un = '';
  let formatedUrl = '';
  const cryptedUrlQuery = window.location.search;
  const params = new URLSearchParams(cryptedUrlQuery);

  const cryptedcompanyToken = params.get('c');
  if (cryptedcompanyToken) {
    console.log(urlCompanyId, 'urlCompanyId');
    const enCryptedcompanyToken = CryptoJS.AES.decrypt(cryptedcompanyToken, 'companyToken');
    try {
      console.log(urlCompanyId, 'urlCompanyId1');
      const companyToken = enCryptedcompanyToken.toString(CryptoJS.enc.Utf8);
      console.log(urlCompanyId, 'urlCompanyId2');
      if (companyToken.length > 0) {
        urlCompanyId = enCryptedcompanyToken.toString(CryptoJS.enc.Utf8);
        console.log(urlCompanyId, 'urlCompanyId3');
      } else {
        urlCompanyId = '';
        console.log(urlCompanyId, 'urlCompanyId4');
      }
    } catch (error) {
      console.log(urlCompanyId, 'urlCompanyId5');
      try {
        const companyToken = enCryptedcompanyToken.toString(CryptoJS.enc.Hex);
        console.log(urlCompanyId, 'urlCompanyId6');
        if (companyToken.length > 0) {
          urlCompanyId = enCryptedcompanyToken.toString(CryptoJS.enc.Hex);
          console.log(urlCompanyId, 'urlCompanyId7');
        }
      } catch {
        urlCompanyId = '';
        console.log(urlCompanyId, 'urlCompanyId8');
      }
    }
  }

  const cryptedAwn = params.get('awn');
  if (cryptedAwn !== null && cryptedAwn !== '') {
    urlAwn = cryptedAwn;
  }

  const cryptedjwtToken = params.get('u');
  if (cryptedjwtToken) {
    const enCryptedjwtToken = CryptoJS.AES.decrypt(cryptedjwtToken, 'userToken');
    // urlJWTtoken = enCryptedjwtToken.toString(CryptoJS.enc.Utf8);
    try {
      const str = enCryptedjwtToken.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        urlJWTtoken = enCryptedjwtToken.toString(CryptoJS.enc.Utf8);
      } else {
        urlJWTtoken = '';
      }
      console.log('isError res', urlJWTtoken);
    } catch (e) {
      console.log('isError', e);
      try {
        const str = enCryptedjwtToken.toString(CryptoJS.enc.Hex);
        if (str.length > 0) {
          urlJWTtoken = enCryptedjwtToken.toString(CryptoJS.enc.Hex);
        } else {
          urlJWTtoken = '';
        }
        console.log('isError res', urlJWTtoken);
      } catch (e) {
        console.log('isError 2', e);
        urlJWTtoken = '';
      }
    }
  }
  const cryptedAppToken = params.get('a');
  if (cryptedAppToken) {
    const enCryptedappToken = CryptoJS.AES.decrypt(cryptedAppToken, 'appToken');
    try {
      const enAppToken = enCryptedappToken.toString(CryptoJS.enc.Utf8);
      if (enAppToken.length > 0) {
        urlAppToken = enCryptedappToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      try {
        const str = enCryptedappToken.toString(CryptoJS.enc.Hex);
        if (str.length > 0) {
          urlAppToken = enCryptedappToken.toString(CryptoJS.enc.Hex);
        }
      } catch (error) {
        urlAppToken = '';
      }
    }
  }

  const crypteduserIdToken = params.get('ui');
  if (crypteduserIdToken) {
    const enCrypteduserIdToken = CryptoJS.AES.decrypt(crypteduserIdToken, 'userId');
    try {
      const idToken = enCrypteduserIdToken.toString(CryptoJS.enc.Utf8);
      if (idToken.length > 0) {
        urlUserId = enCrypteduserIdToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      const str = enCrypteduserIdToken.toString(CryptoJS.enc.Hex);
      if (str.length > 0) {
        urlUserId = enCrypteduserIdToken.toString(CryptoJS.enc.Hex);
      } else {
        urlUserId = '';
      }
    }
  }

  const cryptedloanIdToken = params.get('li');
  if (cryptedloanIdToken) {
    const enCryptedloanIdToken = CryptoJS.AES.decrypt(cryptedloanIdToken, 'loanIdToken');
    try {
      const LoanId = enCryptedloanIdToken.toString(CryptoJS.enc.Utf8);
      if (LoanId.length > 0) {
        urlLoanId = enCryptedloanIdToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      urlLoanId = enCryptedloanIdToken.toString(CryptoJS.enc.Utf8);
      try {
        const LoanId = enCryptedloanIdToken.toString(CryptoJS.enc.Hex);
        if (LoanId.length > 0) {
          urlLoanId = enCryptedloanIdToken.toString(CryptoJS.enc.Hex);
        }
      } catch (error) {
        urlLoanId = '';
      }
    }

    console.log('urlLoanId', urlLoanId);
  }

  const cryptedleadToken = params.get('l');
  if (cryptedleadToken) {
    const enCryptedleadToken = CryptoJS.AES.decrypt(cryptedleadToken, 'leadToken');
    try {
      const leadToken = enCryptedleadToken.toString(CryptoJS.enc.Utf8);
      if (leadToken.length > 0) {
        urlLead = enCryptedleadToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      const str = enCryptedleadToken.toString(CryptoJS.enc.Hex);
      if (str.length > 0) {
        urlLead = enCryptedleadToken.toString(CryptoJS.enc.Hex);
      } else {
        urlLead = '';
      }
    }

    console.log('urlLead', urlLead);
  }

  const cryptedUrlEmpTypeToken = params.get('em');
  if (cryptedUrlEmpTypeToken) {
    const enCryptedleadToken1 = CryptoJS.AES.decrypt(cryptedUrlEmpTypeToken, 'empTypeToken');
    try {
      const urlEmyToken = enCryptedleadToken1.toString(CryptoJS.enc.Utf8);
      if (urlEmyToken.length > 0) {
        urlEmpTypeToken = enCryptedleadToken1.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      const str = enCryptedleadToken1.toString(CryptoJS.enc.Hex);
      if (str.length > 0) {
        urlEmpTypeToken = enCryptedleadToken1.toString(CryptoJS.enc.Utf8);
      } else {
        urlEmpTypeToken = '';
      }
    }

    console.log('urlEmpTypeToken', urlEmpTypeToken);
  }

  const cryptedUrlEmpSubTypeToken = params.get('ems');
  if (cryptedUrlEmpSubTypeToken) {
    const enCryptedleadToken2 = CryptoJS.AES.decrypt(cryptedUrlEmpSubTypeToken, 'empSubTypeToken');
    try {
      const data = enCryptedleadToken2.toString(CryptoJS.enc.Utf8);
      if (data.length > 0) {
        urlEmpSubTypeToken = enCryptedleadToken2.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      const str = enCryptedleadToken2.toString(CryptoJS.enc.Hex);
      if (str.length > 0) {
        urlEmpSubTypeToken = enCryptedleadToken2.toString(CryptoJS.enc.Hex);
      } else {
        urlEmpSubTypeToken = '';
      }
    }

    console.log('urlEmpSubTypeToken', urlEmpSubTypeToken);
  }
  const cryptedUrlLoanCodeToken = params.get('lc');
  if (cryptedUrlLoanCodeToken) {
    const enCryptedleadToken3 = CryptoJS.AES.decrypt(cryptedUrlLoanCodeToken, 'loanCodeValue');
    try {
      const str = enCryptedleadToken3.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        urlLoanCodeToken = enCryptedleadToken3.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      const data = enCryptedleadToken3.toString(CryptoJS.enc.Hex);
      if (data.length > 0) {
        urlLoanCodeToken = enCryptedleadToken3.toString(CryptoJS.enc.Hex);
      } else {
        urlLoanCodeToken = '';
      }
    }

    console.log('urlLoanCodeToken22', urlLoanCodeToken);
  }

  const cryptedloanTypeIdToken = params.get('lti');
  if (cryptedloanTypeIdToken) {
    const enCryptedloanTypeIdToken = CryptoJS.AES.decrypt(
      cryptedloanTypeIdToken,
      'loanTypeIdToken'
    );
    try {
      const str = enCryptedloanTypeIdToken.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        urlloanTypeId = enCryptedloanTypeIdToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      const data = enCryptedloanTypeIdToken.toString(CryptoJS.enc.Hex);
      if (data.length > 0) {
        urlloanTypeId = enCryptedloanTypeIdToken.toString(CryptoJS.enc.Hex);
      } else {
        urlloanTypeId = '';
      }
    }
  }
  console.log('urlloanTypeIdToken', urlloanTypeId);

  const cryptedLoanIdNewToken = params.get('lt');
  if (cryptedLoanIdNewToken) {
    const enCryptedloanIdNewToken = CryptoJS.AES.decrypt(cryptedLoanIdNewToken, 'loanIdNewToken');
    try {
      const str = enCryptedloanIdNewToken.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        urlLoanNewId = enCryptedloanIdNewToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      try {
        const data = enCryptedloanIdNewToken.toString(CryptoJS.enc.Hex);
        if (data.length > 0) {
          urlLoanNewId = enCryptedloanIdNewToken.toString(CryptoJS.enc.Hex);
        }
      } catch (error) {
        urlLoanNewId = '';
      }
    }
  }
  const cryptedSubLoanIdToken = params.get('lts');
  if (cryptedSubLoanIdToken) {
    const enCryptedsubLoanIdToken = CryptoJS.AES.decrypt(cryptedSubLoanIdToken, 'subLoanIdToken');
    try {
      const str = enCryptedsubLoanIdToken.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        urlLoanSubId = enCryptedsubLoanIdToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      try {
        const data = enCryptedsubLoanIdToken.toString(CryptoJS.enc.Hex);
        if (data.length > 0) {
          urlLoanSubId = enCryptedsubLoanIdToken.toString(CryptoJS.enc.Hex);
        }
      } catch (error) {
        urlLoanSubId = '';
      }
    }
  }
  const cryptedEmpTypeNewIdToken = params.get('et');
  if (cryptedEmpTypeNewIdToken) {
    const enCryptedempTypeNewIdToken = CryptoJS.AES.decrypt(
      cryptedEmpTypeNewIdToken,
      'empTypeNewIdToken'
    );
    try {
      const data = enCryptedempTypeNewIdToken.toString(CryptoJS.enc.Utf8);
      if (data.length > 0) {
        urlEmpId = enCryptedempTypeNewIdToken.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      try {
        const str = enCryptedempTypeNewIdToken.toString(CryptoJS.enc.Hex);
        if (str.length > 0) {
          urlEmpId = enCryptedempTypeNewIdToken.toString(CryptoJS.enc.Hex);
        }
      } catch (error) {
        urlEmpId = '';
      }
    }
  }
  const cryptedSubEmpTypeIdToken = params.get('ets');
  if (cryptedSubEmpTypeIdToken) {
    const enCryptedsubEmpTypeIdToken = CryptoJS.AES.decrypt(
      cryptedSubEmpTypeIdToken,
      'subEmpTypeIdToken'
    );
    try {
      const enCryptedsubEmType = enCryptedsubEmpTypeIdToken.toString(CryptoJS.enc.Utf8);
      if (enCryptedsubEmType.length > 0) {
        urlEmpSubId = enCryptedsubEmpTypeIdToken.toString(CryptoJS.enc.Utf8);
      }
    } catch {
      const enCryptedsubEmType = enCryptedsubEmpTypeIdToken.toString(CryptoJS.enc.Hex);
      if (enCryptedsubEmType.length > 0) {
        urlEmpSubId = enCryptedsubEmpTypeIdToken.toString(CryptoJS.enc.Hex);
      } else {
        urlEmpSubId = '';
      }
    }
  }

  // const cryptedLocationToken = params.get('location');
  // if (cryptedLocationToken) {
  //   const encryptedLocationToken = CryptoJS.AES.decrypt(cryptedLocationToken, 'locationUrl');
  //   locationValue = encryptedLocationToken.toString(CryptoJS.enc.Utf8);
  // }
  // console.log('cryptedLocationToken', locationValue);

  const cryptedLocationToken = params.get('location');
  if (cryptedLocationToken) {
    const encryptedLocationToken = CryptoJS.AES.decrypt(cryptedLocationToken, 'locationUrl');
    // urlJWTtoken = enCryptedjwtToken.toString(CryptoJS.enc.Utf8);
    try {
      const str = encryptedLocationToken.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        locationValue = encryptedLocationToken.toString(CryptoJS.enc.Utf8);
      } else {
        locationValue = '';
      }
      console.log('isError res', locationValue);
    } catch (e) {
      console.log('isError', e);
      try {
        const str = encryptedLocationToken.toString(CryptoJS.enc.Hex);
        if (str.length > 0) {
          locationValue = encryptedLocationToken.toString(CryptoJS.enc.Hex);
        } else {
          locationValue = '';
        }
        console.log('isError res', locationValue);
      } catch (e) {
        console.log('isError 2', e);
        locationValue = '';
      }
    }
  }

  // admin

  // get userToken code
  const userDetails = sessionStorage.getItem('userDetails');
  console.log('newuserDetails', userDetails);
  if (
    userDetails &&
    userDetails !== null &&
    userDetails !== undefined &&
    userDetails !== 'null' &&
    userDetails !== 'undefined'
  ) {
    const parsedData = JSON.parse(userDetails);
    console.log('newuserDetails', parsedData);
    u = parsedData.token.userId;
    c = parsedData.token.companyId;
    a = parsedData.token.jwt;
    s = parsedData.token.status;
    m = parsedData.token.message;
    sc = parsedData.token.statusCode;
    un = parsedData.token.userFullName;
    formatedUrl = `?u=${u}&s=${s}&a=${a}&sc=${sc}&c=${c}&m=${m}&un=${un}`;
  }

  return {
    userToken,
    companyToken,
    appToken,
    leadToken,
    userIdToken,
    urlCompanyId,
    cryptedUrlQuery,
    urlAppToken,
    urlUserId,
    urlJWTtoken,
    urlLoanId,
    urlloanTypeId,
    urlLoanNewId,
    urlLoanSubId,
    urlEmpId,
    urlEmpSubId,
    urlLead,
    urlLoanCodeToken,
    urlEmpTypeToken,
    urlEmpSubTypeToken,
    u,
    c,
    a,
    s,
    m,
    sc,
    formatedUrl,
    urlAwn,
    locationValue
  };
}

export function getClientId() {
  let clientId = 'kcb-uat';
  const hostName = window.location.hostname;
  let env;
  let bankName;

  try {
    const data = JSON.parse(sessionStorage.getItem('devEnvDetails'));
    env = data.clientId;
    bankName = data.hostName;
  } catch (error) {
    console.log();
  }

  if (hostName === 'kreditgro-klassdev.kalolytic.com' || (env === 'Dev' && bankName === 'Prime')) {
    clientId = 'pb-dev';
  }
  if (hostName === 'klass-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Prime')) {
    clientId = 'pb-uat';
  }
  //  for prime bank environment ---
  if (hostName === 'pb.kalolytic.com' || (env === 'Prod' && bankName === 'Prime')) {
    clientId = 'pb';
  }
  //  for prime bank uat environment ---
  if (hostName === 'pb-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Prime')) {
    clientId = 'pb-uat';
  }
  // for prime bank dev environment ---
  if (hostName === 'pb-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Prime')) {
    clientId = 'pb-dev';
  }
  //  for kalupur environment ---
  if (hostName === 'kcb.kalolytic.com' || (env === 'Prod' && bankName === 'Kalupur')) {
    clientId = 'kcb';
  }
  //  for kalupur onpremise uat environment ---
  if (hostName === 'myloanuat.kalupurbank.com' || (env === 'Prod' && bankName === 'Kalupur')) {
    clientId = 'kcb';
  }
  //  for kalupur environment prod environment ---
  if (hostName === 'myloan.kalupurbank.com' || (env === 'Prod' && bankName === 'Kalupur')) {
    clientId = 'kcb';
  }
  // *****************************************************************************
  // ┓┏┓┳┓┏┓┳┓┳┏┳┓┏┓┳┓┏┓┓ ┏  ┏┓┳┓┏┓┳┓┳┳┏┓┏┳┓┳┏┓┳┓
  // ┃┫ ┣┫┣ ┃┃┃ ┃ ┃┓┣┫┃┃┃┃┃  ┃┃┣┫┃┃┃┃┃┃┃  ┃ ┃┃┃┃┃
  // ┛┗┛┛┗┗┛┻┛┻ ┻ ┗┛┛┗┗┛┗┻┛  ┣┛┛┗┗┛┻┛┗┛┗┛ ┻ ┻┗┛┛┗
  // *****************************************************************************
  if (hostName === 'pb.kreditgro.com' || (env === 'Prod' && bankName === 'Prime')) {
    clientId = 'pb';
  }

  //  for kalupur  uat environment ---
  if (hostName === 'kcb-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Kalupur')) {
    clientId = 'kcb-uat';
  }
  //  for kalupur dev environment ---
  if (hostName === 'kcb-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Kalupur')) {
    clientId = 'kcb-dev';
  }
  //  for kadi bank environment
  if (hostName === 'knsb.kalolytic.com' || (env === 'Prod' && bankName === 'Kadi')) {
    clientId = 'knsb';
  }
  //  for kadi bank uat environment
  if (hostName === 'knsb-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Kadi')) {
    clientId = 'knsb-uat';
  }
  //  for kadi dev environment ---
  if (hostName === 'knsb-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Kadi')) {
    clientId = 'knsb-dev';
  }
  // for sutex environment ---
  if (hostName === 'sutex.kalolytic.com' || (env === 'Prod' && bankName === 'Sutex')) {
    clientId = 'sutex';
  }

  if (hostName === 'st.kreditgro.com' || (env === 'Prod' && bankName === 'Sutex')) {
    clientId = 'st';
  }

  if (hostName === 'sd.kalolytic.com' || (env === 'Prod' && bankName === 'Sarvodaya')) {
    clientId = 'sd';
  }

  // for sutex uat environment ---
  if (hostName === 'sutex-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Sutex')) {
    clientId = 'sutex-uat';
  }
  // for sutex dev environment ---
  if (hostName === 'sutex-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Sutex')) {
    clientId = 'sutex-dev';
  }
  // for sutex dev environment ---
  if (hostName === 'kreditgro-klassuat.kalolytic.com' || (env === 'UAT' && bankName === 'Prime')) {
    clientId = 'pb-uat';
  }
  return clientId;
}

export function truncateText(text) {
  const truncatedText = text?.length > 40 ? `${text?.substr(0, 40)} ...` : text;
  console.log('truncatetext', truncatedText);
  return truncatedText;
}

export const getValidationMessage = (selectedField, journeyData, value, fieldType) => {
  console.log(
    'selectedField76756',
    selectedField,
    journeyData,
    value,
    fieldType,
    selectedField?.validations
  );
  let errorMessage = '';
  selectedField?.validations?.some((validation) => {
    const functionCode = validation.functionCode;
    const validationInputs = validation.validationInputs;
    console.log('validation1111', validation.validationInputs);
    if (fieldType === 'NUMBER_FIELD') {
      console.log('numberfield');
      if (functionCode === 'BETWEEN') {
        const minValue = parseInt(
          validationInputs.find((input) => input.inputCode === 'MIN').inputValue,
          10
        );
        const maxValue = parseInt(
          validationInputs.find((input) => input.inputCode === 'MAX').inputValue,
          10
        );
        if (Number(value) < minValue || Number(value) > maxValue) {
          errorMessage = validation.message;
          return true; // Break out of the loop
        }
      } else {
        const inputValue = parseInt(validationInputs[0].inputValue, 10);
        console.log(
          'inputValue',
          typeof inputValue,
          functionCode,
          typeof value,
          Number(value) > inputValue
        );
        if (
          (functionCode === 'GREATER_THAN' && Number(value) <= inputValue) ||
          (functionCode === 'LESS_THAN' && Number(value) >= inputValue) ||
          (functionCode === 'LESS_THAN_EQUAL' && Number(value) > inputValue) ||
          (functionCode === 'GREATER_THAN_EQUAL' && Number(value) < inputValue)
        ) {
          console.log('lessthan');
          errorMessage = validation?.message;
          return true; // Break out of the loop
        }

        if (functionCode === 'LENGTH') {
          const minValue = parseInt(
            validationInputs.find((input) => input.inputCode === 'MIN_LENGTH').inputValue,
            10
          );
          const maxValue = parseInt(
            validationInputs.find((input) => input.inputCode === 'MAX_LENGTH').inputValue,
            10
          );
          console.log('insidetextfield', minValue, maxValue);
          if (value?.toString()?.length < minValue || value?.toString()?.length > maxValue) {
            errorMessage = validation?.message;
            return true; // Break out of the loop
          }
        }
        if (functionCode === 'DECIMAL_FORMAT') {
          const maxDigitsBeforeDecimal = validation.validationInputs.find(
            (input) => input.inputCode === 'MAX_DIGITS_BEFORE_DECIMAL'
          )?.inputValue;

          const maxDigitsAfterDecimal = validation.validationInputs.find(
            (input) => input.inputCode === 'MAX_DIGITS_AFTER_DECIMAL'
          )?.inputValue;

          const maxBeforeDecimal = parseInt(maxDigitsBeforeDecimal, 10);
          const maxAfterDecimal = parseInt(maxDigitsAfterDecimal, 10);
          console.log(
            'maxDigitsBeforeDecimal',
            maxDigitsBeforeDecimal,
            maxDigitsAfterDecimal,
            maxBeforeDecimal,
            maxAfterDecimal,
            value
          );
          const splitValue = value?.split('.');
          if (
            splitValue[0]?.toString()?.length > maxBeforeDecimal ||
            splitValue[1]?.toString()?.length > maxAfterDecimal
          ) {
            errorMessage = validation?.message;
            return true; // Break out of the loop
          }
        }
      }
      return false;
    } else if (fieldType.includes('TEXT_FIELD')) {
      console.log('22222', validationInputs);
      if (functionCode === 'PATTERN') {
        const regexPattern = validationInputs?.find(
          (input) => input.inputCode === 'INPUT_VALUE'
        ).inputValue;
        console.log('regexpattern', regexPattern);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphanumericRegex = /^[a-zA-Z0-9 ]+$/;
        const numericRegex = /^[0-9]+$/;
        const alphabeticRegex = /^[a-zA-Z ]+$/;
        const capsAlphaNumericRegex = /^[A-Z0-9 ]+$/;
        if (regexPattern === 'EMAIL' && !emailRegex?.test(value)) {
          errorMessage = validation?.message;
          return true;
        }
        if (
          regexPattern === 'ALPHA_NUMERIC' &&
          (selectedField?.customFieldName.includes('PAN') ||
          selectedField?.customFieldName.includes('Voter')
            ? !capsAlphaNumericRegex?.test(value)
            : !alphanumericRegex?.test(value))
        ) {
          errorMessage = validation?.message;
          return true;
        }
        if (regexPattern === 'NUMERIC' && !numericRegex?.test(value)) {
          errorMessage = validation?.message;
          return true;
        }
        if (regexPattern === 'ALPHABETS' && !alphabeticRegex?.test(value)) {
          errorMessage = validation?.message;
          return true;
        }
      } else if (functionCode === 'LENGTH') {
        const minValue = validationInputs.find(
          (input) => input.inputCode === 'MIN_LENGTH'
        ).inputValue;

        const maxValue = validationInputs.find(
          (input) => input.inputCode === 'MAX_LENGTH'
        ).inputValue;
        console.log('insidetextfield', minValue, maxValue, value);
        if (value?.length < Number(minValue) || value?.length > Number(maxValue)) {
          errorMessage = validation?.message;
        }
      }
      return false;
    } else if (fieldType === 'DATE') {
      console.log('222DATEE');
      if (functionCode === 'IN_RANGE') {
        const minValue = validationInputs.find(
          (input) => input.inputCode === 'MIN_DATE'
        ).inputValue;

        const maxValue = validationInputs.find(
          (input) => input.inputCode === 'MAX_DATE'
        ).inputValue;

        console.log('insidetextDATEfield', minValue, maxValue, value);
        const originalDate = new Date(value);

        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        console.log('formatteddate', formattedDate);

        const targetTime = new Date(formattedDate).getTime();
        const startTime = new Date(minValue).getTime();
        const endTime = new Date(maxValue).getTime();

        if (targetTime < startTime || targetTime > endTime) {
          console.log('validatetime', validation.message);
          errorMessage = validation?.message;
          return true;
        }
      } else {
        const inputValue = validationInputs[0].inputValue;
        console.log('insidetextDATEBEFOREfield', inputValue, value);
        const originalDate = new Date(value);

        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        console.log('formatteddate', formattedDate);

        const targetTime = new Date(formattedDate).getTime();
        const startTime = new Date(inputValue).getTime();

        if (
          (functionCode === 'BEFORE' && targetTime > startTime) ||
          (functionCode === 'AFTER' && targetTime < startTime)
        ) {
          errorMessage = validation?.message;
          return true; // Break out of the loop
        }
      }
      return false;
    }
    return false;
  });
  return errorMessage;
};

export const handleVAPT = (event, fieldCode, module, fieldType) => {
  console.log('fieldtypefdsgfsd', fieldType, event.target.value);
  const newValue = event.target.value;
  const maxLength = fieldType.includes('TEXT_FIELD_')
    ? parseInt(fieldType.split('_')[2], 10)
    : fieldType === 'TEXT_AREA'
    ? 1000
    : fieldType === 'NUMBER_FIELD' || fieldType === 'Number' || fieldType === 'AMOUNT_FIELD'
    ? 50
    : fieldType === 'TEXT_FIELD' || fieldType === 'String'
    ? 500
    : 10000;
  const specialChars = new Map();
  let disallowedChars = [
    '&',
    '$',
    '#',
    '^',
    '*',
    '(',
    ')',
    '?',
    '/',
    '{',
    '}',
    '+',
    '=',
    '~',
    '`',
    '!',
    ':',
    ';',
    '@',
    '_',
    '.',
    '%',
    '[',
    ']',
    "'",
    '"',
    ' '
  ];

  if (fieldCode === 'EMAIL') {
    disallowedChars = disallowedChars.filter((char) => !'@!#$%&*+-/=?^_`{|}~,.'.includes(char));
  }

  if (fieldType?.includes('TEXT_AREA')) {
    disallowedChars = disallowedChars.filter((char) => !'+=_-@%&(){}?,./:$!#^* '.includes(char));
  }

  if (
    (fieldType.includes('TEXT_FIELD') || fieldType === 'String' || fieldType === 'TEXT_AREA') &&
    !fieldType?.includes('TEXT_AREA') &&
    fieldCode !== 'EMAIL'
  ) {
    disallowedChars = disallowedChars.filter((char) => !'@%&(){}+?,./:$!#^* '.includes(char));
  }

  if (fieldType === 'ALPHABET') {
    disallowedChars = disallowedChars.filter((char) => !' '.includes(char));
  }

  if (
    (fieldType === 'NUMBER_FIELD' || fieldType === 'Number') &&
    !fieldType?.includes('TEXT_AREA') &&
    fieldCode !== 'EMAIL'
  ) {
    disallowedChars = disallowedChars.filter((char) => !'.-'.includes(char));
  }

  if (fieldType === 'AMOUNT_FIELD' && !fieldType?.includes('TEXT_AREA') && fieldCode !== 'EMAIL') {
    disallowedChars = disallowedChars.filter((char) => !'.-,'.includes(char));
  }

  let newError = '';

  console.log('fieldTYFDSHJFKHDS', fieldType, newValue, newValue.length, maxLength);

  if (
    ((fieldType.includes('TEXT_FIELD') || fieldType === 'String' || fieldType === 'TEXT_AREA') &&
      newValue.length > maxLength) ||
    ((fieldType === 'NUMBER_FIELD' || fieldType === 'Number' || fieldType === 'AMOUNT_FIELD') &&
      newValue.toString().length > maxLength)
  ) {
    newError = `Input cannot exceed ${maxLength} characters.`;
  }
  if (disallowedChars.some((char) => newValue.includes(char))) {
    newError = 'Input cannot contain invalid characters.';
  }
  if (!/^-?\d*\.?\d*$/.test(newValue) && (fieldType === 'NUMBER_FIELD' || fieldType === 'Number')) {
    newError = 'Invalid input, enter only numbers (including negative numbers and decimals).';
  }
  if (!/^-?(?!,$)\d*\.?\d*(,\d*)*\.?\d*$/.test(newValue) && fieldType === 'AMOUNT_FIELD') {
    console.log('newValue:', newValue);
    newError =
      'Invalid input, enter only numbers (including negative numbers and decimals with or without comma).';
  }

  if (!/^[a-zA-Z ]*$/.test(newValue) && fieldType === 'ALPHABET') {
    newError = 'Invalid input, enter only alphabets.';
  }

  // Additional validation for <sometext/> or <sometext>some random text</sometext>
  const tagPattern = /<\/?[^>]*>/;
  if (tagPattern.test(newValue)) {
    newError = 'Input cannot contain HTML/XML-like tags.';
  }

  Array.from(newValue).forEach((char) => {
    if (!char.match(/[a-zA-Z0-9\s]/)) {
      specialChars.set(char, (specialChars.get(char) || 0) + 1);
    }
  });

  for (const [char, count] of specialChars) {
    if ((fieldType !== 'TEXT_AREA' && count > 100) || (fieldType === 'TEXT_AREA' && count > 100)) {
      newError = `Same special character ${char} cannot exceed more than ${count - 1} times.`;
    }
  }

  return newError;
};

export function validateInput(inputText) {
  console.log('inputeText', inputText);

  const textWithoutTags = inputText?.replace('<p>', '')?.replace('</p>', '');
  const tagPattern = /&lt;\/?[^&]*&gt;/;

  // Check for the presence of HTML/XML tags
  // if (tagPattern.test(textWithoutTags)) {
  //   return false;
  // }

  // Return true if no encoded HTML/XML tags are found
  return true;
}
